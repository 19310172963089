import React, { useState } from "react";
import { logout } from "../../firebaseHelpers";
import Gravatar from "react-gravatar";
import { Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

export const Avatar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userEmail = props.userEmail;
  return (
    <>
      <Gravatar
        email={userEmail}
        size={36}
        style={{ borderRadius: 8, marginRight: 12 }}
        onClick={handleClick}
      />
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "account-menu",
        }}
      >
        <MenuItem onClick={handleClose}>Profile</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  );
};
