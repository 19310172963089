import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { dateFormatter } from "../utils/dates";
import { ErrorBoundary } from "../ErrorBoundary";
import { MyPaper } from "../UI/MyPaper";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { auth, checkAlreadyAnswered } from "../firebaseHelpers";
import { useAuthState } from "react-firebase-hooks/auth";

export default function QuestionsTable(props) {
  const questions = props.questions;
  const [hasAnswers, setHasAnswers] = useState({});
  const [user] = useAuthState(auth);
  const userId = user.uid;

  useEffect(() => {
    checkAlreadyAnswered(questions, userId)
      .then((res) => {
        setHasAnswers(res);
      })
      .catch((err) => console.log("Error fetching answered status: ", err));
  }, [questions, userId]);

  const navigate = useNavigate();

  return (
    <ErrorBoundary>
      <TableContainer component={MyPaper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Question</TableCell>
              <TableCell align="center">Your Bet Placed</TableCell>
              <TableCell align="center">Bet Deadline</TableCell>
              <TableCell align="center">Resolution Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(questions).map((q) => {
              const [id, qData] = q;
              return (
                <TableRow
                  key={qData.shortText}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => {
                    hasAnswers[id]
                      ? navigate("/review/" + id)
                      : navigate("/answer/" + id);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {qData.questionText}
                  </TableCell>
                  <TableCell align="center">
                    {hasAnswers[id] && <CheckCircleIcon color="primary" />}
                  </TableCell>
                  <TableCell align="center">{dateFormatter(qData.betDeadline)}</TableCell>
                  <TableCell align="center">
                    {dateFormatter(qData.betResolutionDate)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ErrorBoundary>
  );
}
