import React from "react";
import { auth, signInWithGoogle } from "../../firebaseHelpers";
import { useAuthState } from "react-firebase-hooks/auth";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ErrorBoundary } from "../../ErrorBoundary";
import { Link } from "react-router-dom";
import { Avatar } from "./Avatar";

const TopBar = () => {
  const [user, loading] = useAuthState(auth);
  const loggedIn = user && !loading;
  const userEmail = user ? user.email : undefined;

  return (
    <>
      <ErrorBoundary>
        <AppBar position="static" elevation={0} style={{ marginBottom: 24 }}>
          <Toolbar sx={{ flexWrap: "wrap" }}>
            <Typography noWrap sx={{ flexGrow: 1 }}>
              <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                BetWorthlessInternetPoints
              </Link>
            </Typography>

            {loggedIn ? (
              <>
                <Avatar userEmail={userEmail} />
              </>
            ) : (
              <Button variant="contained" onClick={signInWithGoogle}>
                Login
              </Button>
            )}
          </Toolbar>
        </AppBar>
      </ErrorBoundary>
    </>
  );
};
export default TopBar;
