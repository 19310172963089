import React from "react";
import Slider from "@mui/material/Slider";

const valuetext = (value, index) => `${Math.round(100 * value)}%`;

const handleChange = (event, setter) => {
  const val = Math.round(event.target.value * 100) / 100;
  setter(val);
};
export const BinaryAnswerWidget = (props) => {
  const val = props.answer;
  const valSetter = props.answerSetter;
  return (
    <Slider
      aria-label="Your Answer (Probability)"
      valueLabelFormat={valuetext}
      valueLabelDisplay="auto"
      value={val}
      defaultValue={0.5}
      step={0.01}
      min={0.01}
      max={0.99}
      onChange={(e) => handleChange(e, valSetter)}
    />
  );
};
