import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/AddCircleRounded";
import { useNavigate } from "react-router-dom";

const CreateQuestionButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      color="primary"
      variant="contained"
      style={{ marginLeft: 24, float: "left" }}
      startIcon={<AddIcon />}
      onClick={() => navigate("/create-question")}
    >
      Add Question
    </Button>
  );
};

export default CreateQuestionButton;
