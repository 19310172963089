import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db, auth } from "../../firebaseHelpers";
import { useAuthState } from "react-firebase-hooks/auth";

export const SubmitAnswerButton = (props) => {
  const questionId = props.questionId;
  const answer = props.answer;
  const validator = props.validator;
  const setSnackOpen = props.setSnackOpen;
  const setSnackMessage = props.setSnackMessage;
  const [user] = useAuthState(auth);

  async function submitAnswerToFirebase(questionId, answer) {
    const docData = {
      questionId: questionId,
      answer: Number(answer),
      answerSubmitData: serverTimestamp(),
      userUid: user.uid,
    };
    await addDoc(collection(db, "Answers"), docData)
      .then(() => {
        setSnackMessage("Answer submitted");
        setSnackOpen(true);
        // in the future, ought to navigate to question summary page
        navigate("/");
        return true;
      })
      .catch((e) => {
        alert("Answer submission failed");
        console.error(e);
        return false;
      });
  }

  function handleSubmission() {
    if (validator(answer)) {
      submitAnswerToFirebase(questionId, answer);
    } else {
      alert("Answer is wrong format or submission failed.");
      return false;
    }
  }

  const navigate = useNavigate();
  return (
    <Button
      color="primary"
      variant="contained"
      style={{ marginTop: 24, float: "left" }}
      onClick={handleSubmission}
    >
      Submit Answer
    </Button>
  );
};
