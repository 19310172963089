import { useState } from "react";
import { ErrorBoundary } from "../../ErrorBoundary";
import { MyPaper } from "../../UI/MyPaper";
import { MyDatePicker } from "../../UI/MyDatePicker";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getSubmissionErrors, makeSubmission } from "../../utils/createQuestionUtils";
import { auth } from "../../firebaseHelpers";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { addDays, toYYYYMMDD } from "../../utils/dates";

export const CreateQuestionPage = (props) => {
  const maxInvitees = 12;
  const maxQuestionLength = 180;
  const maxContextLength = 800;
  const [user] = useAuthState(auth);

  const sevenDaysFromNowString = toYYYYMMDD(addDays(new Date(), 7));
  const thirtyDaysFromNowString = toYYYYMMDD(addDays(new Date(), 30));

  const [betDeadline, setBetDeadline] = useState(sevenDaysFromNowString);
  const [betResolutionDate, setBetResolutionDate] = useState(thirtyDaysFromNowString);
  const [questionText, setQuestionText] = useState("");
  const [contextText, setContextText] = useState("");
  const [questionType, setQuestionType] = useState("Numeric");
  const [inviteeEmails, setInviteeEmails] = useState([
    "example@gmail.com",
    ...Array(maxInvitees - 1).fill(""),
  ]);
  const [errorMessages, setErrorMessages] = useState([]);

  const handleQuestionTextChange = (event) => {
    setQuestionText(event.target.value);
  };

  const handleContextTextChange = (event) => {
    setContextText(event.target.value);
  };

  const handleQuestionTypeChange = (event) => {
    setQuestionType(event.target.value);
  };

  const navigate = useNavigate();

  const handleSubmitRequest = (event) => {
    // Returns true if a submission occurred. Otherwise returns false
    const errors = getSubmissionErrors(
      questionText,
      contextText,
      betDeadline,
      betResolutionDate,
      inviteeEmails,
      maxQuestionLength,
      maxContextLength
    );
    setErrorMessages(errors);
    if (errors.length > 0) {
      return false;
    } else {
      const submissionSuccess = makeSubmission(
        questionText,
        contextText,
        questionType,
        betDeadline,
        betResolutionDate,
        inviteeEmails,
        user
      );
      if (submissionSuccess) {
        props.setSnackMessage(
          "Question Created. Tell anyone you invited, because we don't have notifacations yet"
        );
        props.setSnackOpen(true);
        navigate("/");
      }
    }
  };

  const handleEmailsChange = (event, emailNumber) => {
    const newEntry = event.target.value.toLowerCase();
    const emailsBeforeEdit = inviteeEmails.slice(0, emailNumber);
    const emailsAfterEdit = inviteeEmails.slice(emailNumber + 1);
    const newEmailList = [...emailsBeforeEdit, newEntry, ...emailsAfterEdit];
    setInviteeEmails(newEmailList);
  };

  const contextTooLong = contextText.length > maxContextLength;
  const questionTextTooLong = questionText.length > maxQuestionLength;

  return (
    <>
      <ErrorBoundary>
        <div style={{ margin: 24 }}>
          <h1>Create A Question</h1>
          <p>Note: this page doesn't work yet</p>
          <MyPaper style={{ padding: 12 }}>
            <FormControl>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={3}>
                  Question (max {maxQuestionLength} characters)
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    id="question text"
                    value={questionText}
                    onChange={handleQuestionTextChange}
                    helperText={questionTextTooLong ? "Question is too long" : ""}
                  />
                </Grid>
                <Grid item xs={3}>
                  Question outcome type
                </Grid>
                <Grid item xs={9}>
                  <Select
                    labelId="question-type-selector-label"
                    id="question-type-selector"
                    value={questionType}
                    onChange={handleQuestionTypeChange}
                  >
                    <MenuItem value="Numeric">Numeric</MenuItem>
                    <MenuItem value="Binary">Yes / No</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  Other information or context:
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    fullWidth
                    id="context"
                    value={contextText}
                    onChange={handleContextTextChange}
                    helperText={contextTooLong ? "Extended information is too long" : ""}
                  />
                </Grid>
                <Grid item xs={3}>
                  Deadline for placing bets
                </Grid>
                <Grid item xs={9}>
                  <MyDatePicker date={betDeadline} dateSetter={setBetDeadline} />
                </Grid>
                <Grid item xs={3}>
                  Date bet will be resolved
                </Grid>
                <Grid item xs={9}>
                  <MyDatePicker
                    date={betResolutionDate}
                    dateSetter={setBetResolutionDate}
                  />
                </Grid>
                <Grid item xs={3}>
                  Email addresses of people to invite to this question. (Leave extras
                  blank.)
                </Grid>
                <Grid item xs={9}>
                  {inviteeEmails.map((emailAddress, i) => (
                    <TextField
                      key={"email-" + i}
                      value={emailAddress}
                      autoComplete={"off"}
                      onChange={(event) => handleEmailsChange(event, i)}
                      style={{ paddingRight: 8, paddingBottom: 8 }}
                    />
                  ))}
                </Grid>
              </Grid>
            </FormControl>
          </MyPaper>
        </div>
        {errorMessages.map((err) => (
          <div
            style={{ marginLeft: 24, color: "red", fontWeight: "bold" }}
            key={"error_" + err}
          >
            {err}
          </div>
        ))}
      </ErrorBoundary>
      <div>
        <Button
          variant="contained"
          style={{ marginLeft: 24, float: "left" }}
          onClick={handleSubmitRequest}
        >
          Submit Question
        </Button>
      </div>
    </>
  );
};
