export const dateFormatter = (input) => {
  return input.toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const toYYYYMMDD = (d) => d.toISOString().split("T")[0];

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};
