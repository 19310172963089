import React, { useEffect, useState } from "react";
import QuestionsTable from "../UI/QuestionsTable";
import { where } from "firebase/firestore";
import { auth, questionsFetcher } from "../firebaseHelpers";
import { useAuthState } from "react-firebase-hooks/auth";

const LiveQuestionsLists = (props) => {
  // TODO: Pass full object of questions and convert QuestionsTable to use links (linked by question Id)
  const [myQuestions, setMyQuestions] = useState({});
  const [inviteeQuestions, setInviteeQuestions] = useState({});
  const [user] = useAuthState(auth);

  useEffect(() => {
    questionsFetcher(where("createdByUid", "==", user.uid))
      .then((res) => setMyQuestions(res))
      .catch((err) => console.log("Error fetching created questions: ", err));

    questionsFetcher(where("inviteeEmails", "array-contains", user.email))
      .then((res) => setInviteeQuestions(res))
      .catch((err) => console.log("Error fetching invited questions: ", err));
  }, [user]);

  return (
    <div style={{ margin: 24 }}>
      <h1>Created Questions</h1>
      <QuestionsTable questions={myQuestions} />

      <h1>Invited Questions</h1>
      <QuestionsTable questions={inviteeQuestions} />
    </div>
  );
};

export default LiveQuestionsLists;
