import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { pullQuestionData } from "../../firebaseHelpers";
import { MyPaper } from "../../UI/MyPaper";
import { dateFormatter } from "../../utils/dates";
import { fetchAllAnswers } from "../../firebaseHelpers";
import { AnswersTable } from "./AnswersTable";

export const ReviewQuestionPage = (props) => {
  const [questionData, setQuestionData] = useState({});
  const { questionId } = useParams([]);
  const [answers, setAnswers] = useState([]);

  const answerSorter = (x) => x.sort((i, j) => i.answer > j.answer);

  useEffect(() => {
    pullQuestionData(questionId)
      .then((res) => setQuestionData(res.data()))
      .catch((err) => console.log("Error fetching question data: ", err));
  }, [questionId]);

  useEffect(() => {
    // Search for previous answer so I can redirect
    fetchAllAnswers(questionId)
      .then((res) => {
        setAnswers(answerSorter(res.map((x) => x.data())));
      })
      .catch((err) => console.log("Error fetching answers data: ", err));
  }, [questionId]);

  return (
    <div style={{ margin: 24 }}>
      <h1>{questionData.questionText}</h1>
      <MyPaper>
        <h2 style={{ paddingTop: 12, textAlign: "center" }}>Bets</h2>
        <AnswersTable answers={answers} />
      </MyPaper>
      <div style={{ paddingTop: 24, paddingLeft: 4 }}>
        <b>Betting Deadline:</b>{" "}
        {questionData.hasOwnProperty("betDeadline")
          ? dateFormatter(questionData.betDeadline.toDate())
          : ""}
      </div>
      <div style={{ paddingLeft: 4 }}>
        <b>Bet Resolution Date:</b>{" "}
        {questionData.hasOwnProperty("betResolutionDate")
          ? dateFormatter(questionData.betResolutionDate.toDate())
          : ""}
      </div>
    </div>
  );
};
