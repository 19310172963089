import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { pullQuestionData } from "../../firebaseHelpers";
import { MyPaper } from "../../UI/MyPaper";
import { dateFormatter } from "../../utils/dates";
import { BinaryAnswerWidget } from "./BinaryAnswerWidget.js";
import { NumericAnswerWidget } from "./NumericAnswerWidget";
import { SubmitAnswerButton } from "./SubmitAnswerButton";
import { checkNumericAnswer } from "./NumericAnswerWidget";
import { auth, fetchOneAnswer } from "../../firebaseHelpers";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

export const AnswerQuestionPage = (props) => {
  const [questionData, setQuestionData] = useState({});
  const [answer, setAnswer] = useState(0);
  const { questionId } = useParams([]);
  const setSnackOpen = props.setSnackOpen;
  const setSnackMessage = props.setSnackMessage;
  // Currently just redirecting if a previous answer exists. Empty array means no answer
  const [prevAnswer, setPrevAnswer] = useState([]);
  const questionType = questionData.questionType;
  const [user] = useAuthState(auth);
  const userId = user ? user.uid : undefined;
  const navigate = useNavigate();

  useEffect(() => {
    if (questionData.betDeadline) {
      // don't navigate away before questionData is loaded
      const newAnswerNotAllowed =
        prevAnswer.length > 0 || Date() > questionData.betDeadline.toDate();
      if (newAnswerNotAllowed) {
        navigate("/review/" + questionId);
      }
    }
  }, [prevAnswer, questionData]);
  useEffect(() => {
    if (questionId.length && userId) {
      fetchOneAnswer(questionId, userId)
        .then((res) => setPrevAnswer(res))
        .catch((err) => console.log("Error fetching previous answer: ", err));
    }
  }, [questionId, userId]);

  useEffect(() => {
    pullQuestionData(questionId)
      .then((res) => setQuestionData(res.data()))
      .catch((err) => console.log("Error fetching question data: ", err));
  }, [questionId]);

  return (
    <div style={{ margin: 24 }}>
      <h1>{questionData.questionText}</h1>
      <MyPaper>
        <div style={{ padding: 12 }}>
          <b>Your Answer:</b>{" "}
          {questionType === "Binary" ? (
            <BinaryAnswerWidget value={answer} answerSetter={setAnswer} />
          ) : (
            <NumericAnswerWidget value={answer} answerSetter={setAnswer} />
          )}
        </div>
        <div style={{ padding: 12 }}>
          <b>Betting Deadline:</b>{" "}
          {questionData.hasOwnProperty("betDeadline")
            ? dateFormatter(questionData.betDeadline.toDate())
            : ""}
        </div>
        <div style={{ padding: 12 }}>
          <b>Bet Resolution Date:</b>{" "}
          {questionData.hasOwnProperty("betResolutionDate")
            ? dateFormatter(questionData.betResolutionDate.toDate())
            : ""}
        </div>
      </MyPaper>
      <SubmitAnswerButton
        setSnackOpen={setSnackOpen}
        setSnackMessage={setSnackMessage}
        validator={questionType === "Numeric" ? checkNumericAnswer : (e) => true}
        questionId={questionId}
        answer={answer}
      />
    </div>
  );
};
