import LiveQuestionsLists from "./LiveQuestionsLists";
import CreateQuestionButton from "./QuestionCreation/CreateQuestionButton";
import { ErrorBoundary } from "../ErrorBoundary";
import { auth } from "../firebaseHelpers";
import { useAuthState } from "react-firebase-hooks/auth";

export const MainView = (props) => {
  const [user, loading] = useAuthState(auth);
  const loggedIn = user && !loading;

  return (
    <>
      <ErrorBoundary>
        {loggedIn ? (
          <>
            <LiveQuestionsLists />
            <CreateQuestionButton />
          </>
        ) : (
          <>
            <h1 style={{ paddingLeft: 24 }}>Create Your Own Prediction Market</h1>
            <p style={{ paddingLeft: 24 }}>
              Sign in, create a question, and invite your friends and track who gets it
              right.
            </p>
            <p style={{ paddingLeft: 24 }}>
              Your question and the results are private to the people you invite.
            </p>
          </>
        )}
      </ErrorBoundary>
    </>
  );
};
