import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, fetchUserName } from "../../firebaseHelpers";

export const AnswersTable = (props) => {
  const answers = props.answers;
  const [user] = useAuthState(auth);
  const [answerAuthorNames, setAnswerAuthorNames] = useState(
    Object.fromEntries(answers.map((a) => [a.userUid, ""]))
  );

  useEffect(() => {
    async function fetchUserNames(answers) {
      const out = answers.map((a) => {
        const uid = a.userUid;
        const p = fetchUserName(uid)
          .then((name) => [uid, name])
          .catch((err) => {
            console.log("error in fetching", err);
          });
        return p;
      });
      return await Promise.all(out);
    }
    fetchUserNames(answers).then((res) => setAnswerAuthorNames(Object.fromEntries(res)));
  }, [answers]);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="left">Bettor</TableCell>
          <TableCell align="left">Bet</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {answers.map((a) => (
          <TableRow>
            <TableCell align="left">
              {user.uid === a.userUid ? "You" : answerAuthorNames[a.userUid]}
            </TableCell>
            <TableCell align="left">{a.answer}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
