import { addDoc, collection, Timestamp, serverTimestamp } from "firebase/firestore";
import { db } from "../firebaseHelpers";

const minQuestionLength = 25;

export const getSubmissionErrors = (
  questionText,
  contextText,
  betDeadline,
  betResolutionDate,
  inviteeEmails,
  maxQuestionLength,
  maxContextLength
) => {
  const findEmailAddressErrors = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email) ? null : email;
  };
  var out = [];
  if (questionText.length > maxQuestionLength) {
    out = out.concat("Question is too long. Max length is " + maxQuestionLength);
  }

  if (new Date(betDeadline) > new Date(betResolutionDate)) {
    out = out.concat("Current dates would let someone bet after the bet is resolved.");
  }

  if (new Date(betDeadline) < new Date()) {
    out = out.concat("The betting deadline is in the past.");
  }

  if (new Date(betResolutionDate) < new Date()) {
    out = out.concat("The bet resolution date is in the past.");
  }

  if (questionText.length < minQuestionLength) {
    out = out.concat("Question is too short");
  }

  if (contextText.length > maxContextLength) {
    out = out.concat(
      "Question extended info/context is too long. Max length is " + maxContextLength
    );
  }
  const emailsWithErrors = inviteeEmails
    .map(findEmailAddressErrors)
    .filter((x) => x !== null && x.length > 0);
  if (emailsWithErrors.length > 0) {
    out = out.concat(
      "The following addresses are not valid email addresses: " +
        emailsWithErrors.join(", ")
    );
  }
  return out;
};

export async function makeSubmission(
  questionText,
  contextText,
  questionType,
  betDeadline,
  betResolutionDate,
  inviteeEmails,
  user
) {
  const docData = {
    questionSubmitDate: serverTimestamp(),
    questionText: questionText,
    contextText: contextText,
    questionType: questionType,
    betDeadline: Timestamp.fromDate(new Date(betDeadline)),
    betResolutionDate: Timestamp.fromDate(new Date(betResolutionDate)),
    inviteeEmails: inviteeEmails,
    createdByEmail: user["email"],
    createdByUid: user["uid"],
  };
  try {
    await addDoc(collection(db, "Questions"), docData);
    console.log("Question submission succeeded");
    return true;
  } catch (e) {
    console.log("Question submission failed.");
    console.log(e);
    return false;
  }
}
