import React from "react";
import TextField from "@mui/material/TextField";

export const checkNumericAnswer = (str) => {
  if (typeof str != "string") {
    console.error("Expected string to convert to number. Received", str);
  }
  return !isNaN(str) && !isNaN(parseFloat(str));
};

const handleChange = (event, setter) => {
  const val = event.target.value;
  setter(val);
  if (val.length >= 0 && !checkNumericAnswer(val)) {
    console.error("Bad value", val);
  }
};
export const NumericAnswerWidget = (props) => {
  const val = props.answer;
  const valSetter = props.answerSetter;
  return (
    <TextField
      id="answer-entry"
      variant="outlined"
      value={val}
      onChange={(e) => handleChange(e, valSetter)}
    />
  );
};
