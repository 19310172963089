import { TextField } from "@mui/material";

export const MyDatePicker = (props) => {
  const date = props.date;
  const dateSetter = props.dateSetter;

  const handleDateChange = (event) => {
    dateSetter(event.target.value);
  };
  return (
    <TextField
      id="datePicker"
      type="date"
      value={date}
      onChange={handleDateChange}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

// TODO: Switch back to material datepicker. Previously tried below but got an error
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers";

// const handleChange = (newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <LocalizationProvider dateAdapter={AdapterDateFns}>
//       <DatePicker
//         label="Some date to pick"
//         inputFormat="MM/dd/yyyy"
//         value={value}
//         onChange={handleChange}
//         renderInput={(params) => <TextField type="date" {...params} />}
//       />
//     </LocalizationProvider>
//   );
