import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  doc,
  getDoc,
} from "firebase/firestore";

const config = {
  apiKey: "AIzaSyBvDht_JOf5jovh1hXHRl-LlcsUqOZOm7k",
  authDomain: "internetpoints.firebaseapp.com",
  databaseURL: "https://internetpoints-default-rtdb.firebaseio.com",
  projectId: "internetpoints",
  storageBucket: "internetpoints.appspot.com",
  messagingSenderId: "585154190405",
  appId: "1:585154190405:web:babb3769d52e47e9a2172c",
  measurementId: "G-GPSCRLKJP1",
};

export const app = initializeApp(config);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const googleProvider = new GoogleAuthProvider();
export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
  }
};

export const logout = () => {
  signOut(auth);
};

export async function pullQuestionData(id) {
  const qRef = doc(db, "Questions", id);
  const qData = await getDoc(qRef);
  return qData;
}

export async function questionsFetcher(qry) {
  const cleanDates = (question) => {
    // Convert firestore dates back into javascript dates
    return {
      ...question,
      betDeadline: question.betDeadline.toDate(),
      betResolutionDate: question.betResolutionDate.toDate(),
      questionSubmitDate: question.questionSubmitDate.toDate(),
    };
  };

  try {
    const myCol = collection(db, "Questions");
    const response = await getDocs(query(myCol, qry));
    // Convert array of question data to object keyed by id (with other data as values) and clean dates
    const out = Object.assign(
      {},
      ...response.docs.map((doc) => ({ [doc.id]: cleanDates(doc.data()) }))
    );
    return out;
  } catch {
    console.log("Did not fetch questions.");
    return {};
  }
}

export async function fetchUserName(userUid) {
  try {
    const myCol = collection(db, "users");
    const qry = where("uid", "==", userUid);
    const name = await getDocs(query(myCol, qry)).then((res) => res.docs[0].data().name);
    return name;
  } catch (e) {
    console.log("Error while fetching user name", e);
    return "";
  }
}

export async function fetchAllAnswers(qId) {
  try {
    const myCol = collection(db, "Answers");
    const qry = where("questionId", "==", qId);
    const response = await getDocs(query(myCol, qry)).then((res) => res.docs);
    return response;
  } catch (e) {
    console.log("Error while fetching answers", e);
  }
}

export async function fetchOneAnswer(qId, userId) {
  try {
    const myCol = collection(db, "Answers");
    const qry1 = where("userUid", "==", userId);
    const qry2 = where("questionId", "==", qId);
    const response = await getDocs(query(myCol, qry1, qry2)).then((res) => res.docs);
    return response;
  } catch (e) {
    console.log("Error while fetching answer", e);
  }
}

export async function checkAlreadyAnswered(questions, userId) {
  try {
    const questionIds = Object.keys(questions);
    const hasAnswers = await Promise.all(
      questionIds.map((qId) => fetchOneAnswer(qId, userId))
    ).then((allRes) => allRes.map((res) => Boolean(res.length)));
    const out = Object.fromEntries(questionIds.map((id, i) => [id, hasAnswers[i]]));
    return out;
  } catch (e) {
    console.log("failed to check for existing answers", e);
    return {};
  }
}
