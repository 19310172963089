import Snackbar from "@mui/material/Snackbar";

// This is a bit of a hack. I want some pages to trigger a change in navigation
// which puts the triggering component out of scope... but then I want a
// snackbar on the page we navigate to. This component can be put in App.js
// so it's always available, and then have props passed to control behavior from
// within some other component
export const HomeSnackBar = (props) => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={6000}
      message={props.message}
      onClose={() => props.setSnackOpen(false)}
    />
  );
};
