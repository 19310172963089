import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TopBar from "./components/TopBar/TopBar";
import CssBaseline from "@mui/material/CssBaseline";
import { MainView } from "./components/MainView";
import { CreateQuestionPage } from "./components/QuestionCreation/CreateQuestionPage";
import { HomeSnackBar } from "./components/HomeSnackBar";
import { AnswerQuestionPage } from "./components/Answering/AnswerQuestionPage";
import { ReviewQuestionPage } from "./components/QuestionReview/ReviewQuestionPage";

function App(props) {
  const [snackMessage, setSnackMessage] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);

  return (
    <>
      <CssBaseline />
      <Router>
        <TopBar />
        <Routes>
          <Route exact path="/" element={<MainView />} />
          <Route
            exact
            path="/create-question"
            element={
              <CreateQuestionPage
                setSnackOpen={setSnackOpen}
                setSnackMessage={setSnackMessage}
              />
            }
          />
          <Route path="/review/:questionId" element={<ReviewQuestionPage />} />

          <Route
            path="/answer/:questionId"
            element={
              <AnswerQuestionPage
                setSnackOpen={setSnackOpen}
                setSnackMessage={setSnackMessage}
              />
            }
          />
        </Routes>
      </Router>
      <HomeSnackBar open={snackOpen} setSnackOpen={setSnackOpen} message={snackMessage} />
    </>
  );
}

export default App;
